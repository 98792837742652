















































































import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import Component from 'vue-class-component';

import { RootState } from '@/store';
import { Prop } from 'vue-property-decorator';
import AnchoredHeading from '@/components/regulations-detail/AnchoredHeading.vue';
import RequirementItem from '@/components/common/RequirementItem.vue';
import { Requirement, RequirementContentType, RequirementMap } from '@/features/sr-model/Requirement';
import SopQmsRequirement from '@/components/regulations-detail/SopQmsRequirement.vue';
import SopQmsRequirementEdit from '@/components/regulations-detail/SopQmsRequirementEdit.vue';
import SopQmsRequirementApplicability from '@/components/regulations-detail/SopQmsRequirementApplicability.vue';
import { RegulationDetailActions, RegulationDetailGetters } from '@/store/regulation-detail';
import { QmsRequirement } from '@/services/model';
import SopQmsRequirementProposal from '@/components/regulations-detail/SopQmsRequirementProposal.vue';

@Component({
  components: { SopQmsRequirementProposal, SopQmsRequirementApplicability, SopQmsRequirementEdit, SopQmsRequirement, AnchoredHeading, RequirementItem },
  computed: {
    ...mapState<RootState>({
      isEditingRequirement(this: RequirementListEntry, state: RootState) {
        return this.source.id === state.regulationDetail.editingRequirementId;
      },
      requirementMap: (state: RootState) => state.regulationDetail.requirements,
      editQmsRequirements: (state: RootState) => state.regulationDetail.editQmsRequirements,
      filterText: (state: RootState) => state.regulationDetail.filter.requirementText,
    }),
    ...mapGetters({
      qmsRequirementBySrRequirementId: RegulationDetailGetters.QMS_REQUIREMENT_BY_REQUIREMENT_ID,
      regulationReferencesByRequirementId: RegulationDetailGetters.REGULATION_REFERENCES_BY_REQUIREMENT_ID,
    }),
  },
  methods: {
    ...mapActions({
      createQmsRequirement: RegulationDetailActions.QMS_REQUIREMENT_CREATE,
    }),
  },
})
export default class RequirementListEntry extends Vue {

  // do not change the naming, we have to satisfy virtual-scroll-list
  @Prop() index: number;
  @Prop() source: Requirement

  requirementMap: RequirementMap;
  filterText: string;
  qmsRequirementBySrRequirementId: (id: string) => QmsRequirement;

  createQmsRequirement: (srRequirementId: string) => Promise<void>;

  createInterpretation() {
    return this.createQmsRequirement(this.source.id);
  }

  isHeading(type: RequirementContentType) {
    return type === RequirementContentType.HEADING;
  }

  get qmsRequirement(): QmsRequirement {
    return this.qmsRequirementBySrRequirementId(this.source.id);
  }

  get isInterpretationVisible(): boolean {
    return this.source.interpretable || this.isDeprecatedInterpretation;
  }

  get interpretedInRequirementInfo(): string {
    if (!this.qmsRequirement.interpretedInRequirementId) {
      throw new Error('QmsRequirement has no expected interpretedInRequirementId');
    }
    const requirement = this.requirementMap[this.qmsRequirement.interpretedInRequirementId];
    const infoText = ((requirement.paragraph ?? '') + ' ' + requirement.text).trim();
    return infoText.substr(0, Math.min(infoText.length, 50)) + (infoText.length > 50 ? '...' : '');
  }

  get isDeprecatedInterpretation(): boolean {
    return !this.source.interpretable
        && (!['INFORMATION', 'NOT_DEFINED'].includes(this.qmsRequirement.applicability)
            || this.qmsRequirement.targetSopArtifactIds.length > 0
            || this.qmsRequirement.actualSopArtifactIds.length > 0
            || this.qmsRequirement.targetTemplateArtifactIds.length > 0
            || this.qmsRequirement.actualTemplateArtifactIds.length > 0
            || this.qmsRequirement.templateContentIds.length > 0);
  }
}
