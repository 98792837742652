









































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { QmsGapValidation, RegulationDetailGetters } from '@/store/regulation-detail';
import { mapActions, mapGetters } from 'vuex';
import { SopGetters } from '@/features/sops';
import { Sop, SopSearchPart } from '@/model';
import { TemplateContentActions, TemplateContentGetters } from '@/features/template-content/template-content-store';
import TemplateContentValidationIcons from '@/components/templates-overview/TemplateContentValidationIcons.vue';
import { Template } from '@/model/Template';
import { TemplateGetters } from '@/store/templates';
import TemplateContentChip from '@/features/template-content/components/TemplateContentChip.vue';
import { QmsRequirement } from '@/services/model';
import { ProductRequirement } from '@/features/sop-block/model';
import { SopBlockListEvents, SopBlockSelectedEvent } from '@/features/sop-block/sop-block-list/sop-block-list-events';
import { UiLayoutActions } from '@/store/ui-layout';

@Component<QmsRequirementCurrent>({
  components: { TemplateContentChip, TemplateContentValidationIcons },
  computed: {
    ...mapGetters({
      selectableSopsByArtifactIds: SopGetters.SELECTABLE_SOPS_BY_ARTIFACT_IDS,
      selectableTemplates: TemplateGetters.VALID_TEMPLATE_ARTIFACTS,
      templateContents: TemplateContentGetters.TEMPLATE_CONTENTS_BY_IDS_OR_LOADING,
      templateContentValidationsById: TemplateContentGetters.TEMPLATE_CONTENT_VALIDATIONS_BY_ID,
      productRequirements: RegulationDetailGetters.PRODUCT_REQUIREMENTS,
      regulationReferencesByRequirementId: RegulationDetailGetters.REGULATION_REFERENCES_BY_REQUIREMENT_ID,
    }),
  },
  methods: {
    ...mapActions({
      selectTemplateContentDetailWidgetId: TemplateContentActions.TEMPLATE_CONTENT_DETAIL_WIDGET_SELECT_ID
    })
  },
})
export default class QmsRequirementCurrent extends Vue {

  @Prop() qmsRequirement: QmsRequirement;

  selectableSopsByArtifactIds: (x: string[]) => SopSearchPart[];
  selectableSopArtifacts: Sop[];
  selectableTemplates: Template[];
  productRequirements: ProductRequirement[];

  get targetAndActualSops(): SopSearchPart[] {
    const targetSops: SopSearchPart[] = this.selectableSopsByArtifactIds(this.qmsRequirement.targetSopArtifactIds);
    const actualSops: SopSearchPart[] = this.selectableSopsByArtifactIds(this.qmsRequirement.actualSopArtifactIds);
    return Array.from(new Set<SopSearchPart>([
      ...targetSops,
      ...actualSops
    ]));
  }

  get targetAndActualTemplates(): Template[] {
    const targetTemplates: Template[] = this.selectableTemplates.filter(template => this.qmsRequirement.targetTemplateArtifactIds.includes(template.artifactId));
    const actualTemplates: Template[] = this.selectableTemplates.filter(template => this.qmsRequirement.actualTemplateArtifactIds.includes(template.artifactId));
    return Array.from(new Set<Template>([
      ...targetTemplates,
      ...actualTemplates
    ]));
  }

  get targetProductRequirements(): ProductRequirement[] {
    return this.productRequirements.filter(pr => this.qmsRequirement.targetSopBlockArtifactIds.includes(pr.sopBlockArtifactId));
  }

  isSopAssignedAsTargetAndImplemented(sopArtifactId: string): boolean {
    return QmsGapValidation.isSopAssignedAsTargetAndImplemented(this.qmsRequirement, sopArtifactId);
  }

  isSopAssignedAsTargetAndNotImplemented(sopArtifactId: string): boolean {
    return QmsGapValidation.isSopAssignedAsTargetAndNotImplemented(this.qmsRequirement, sopArtifactId);
  }

  isSopNotAssignedAsTargetAnymore(sopArtifactId: string): boolean {
    return QmsGapValidation.isSopNotAssignedAsTargetAnymore(this.qmsRequirement, sopArtifactId);
  }

  isTemplateAssignedAsTargetAndImplemented(templateArtifactId: string): boolean {
    return QmsGapValidation.isTemplateAssignedAsTargetAndImplemented(this.qmsRequirement, templateArtifactId);
  }

  isTemplateAssignedAsTargetAndNotImplemented(templateArtifactId: string): boolean {
    return QmsGapValidation.isTemplateAssignedAsTargetAndNotImplemented(this.qmsRequirement, templateArtifactId);
  }

  isTemplateNotAssignedAsTargetAnymore(templateArtifactId: string): boolean {
    return QmsGapValidation.isTemplateNotAssignedAsTargetAnymore(this.qmsRequirement, templateArtifactId);
  }

  openProductRequirementWidget(productRequirement: ProductRequirement) {
    const event: SopBlockSelectedEvent = {
      forEditing: false,
      sopArtifactId: productRequirement.sopArtifactId,
      artifactId: productRequirement.sopBlockArtifactId,
      versionId: productRequirement.sopBlockVersionId,
      name: productRequirement.sopBlockName,
      description: productRequirement.sopBlockDescription,
      productIds: productRequirement.sopBlockProductIds,
      testable: productRequirement.sopBlockTestable,
      acceptanceCriterion: productRequirement.sopBlockAcceptanceCriterion,
      statisticalRequirements: productRequirement.sopBlockStatisticalRequirements,
      sampleSize: productRequirement.sopBlockSampleSize,
      references: productRequirement.sopBlockReferences,
    };
    this.$store.dispatch(SopBlockListEvents.SOP_BLOCK_SELECTED, event)
        .then(() => this.$store.dispatch(UiLayoutActions.SIDEBAR_RIGHT_SELECTION_CHANGE, 'ProductRequirementWidget'));
  }
}
